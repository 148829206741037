<template>
	<div class="col-sm-12">
		<DataGrid :config="dataGridConfig" :data="mensajes" :select="seleccionar" @actions="dataGridActions">
			<div class="row">
				<div class="col-sm-2"><button class="btn warning-btn" @click="obtener_mensajes">Recargar</button></div>
				<div class="col-sm-2"><button class="btn secondary-btn" @click="ver_mensaje">Ver mensaje</button></div>
			</div>
		</DataGrid>

		<Modal v-if="mensaje" :options="{width: '50vw', close: true}" @close="cerrar_mensaje">
			<div class="title">Mensaje</div>
			<div class="body">
				<div class="row form-group">
					<label for="mensaje.nombre" class="col-form-label col-sm-2">Nombre</label>
					<div class="col-sm-10"><input v-model="mensaje.nombre" type="text" name="mensaje.nombre" id="mensaje.nombre" class="form-control" disabled></div>
				</div>
				<div class="row form-group">
					<label for="mensaje.email" class="col-form-label col-sm-2">Email</label>
					<div class="col-sm-4"><input v-model="mensaje.email" type="text" name="mensaje.email" id="mensaje.email" class="form-control" disabled></div>
					<label for="mensaje.telefono" class="col-form-label col-sm-2">Teléfono</label>
					<div class="col-sm-4"><input v-model="mensaje.telefono" type="text" name="mensaje.telefono" id="mensaje.telefono" class="form-control" disabled></div>
				</div>
				<div class="row">
					<label for="" class="col-form-label col-sm-2">Mensaje</label>
					<div class="col-sm-10"><textarea v-model="mensaje.mensaje" name="" id="" cols="30" rows="10" class="form-control" disabled></textarea></div>
				</div>
			</div>
			<div class="footer">
				<div class="col-sm-2 offset-sm-10"><button class="btn secondary-btn" @click="cerrar_mensaje">Cerrar</button></div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGridV2'
	import Modal from '@/components/Modal'

	import api from '@/apps/garantias/api/otros'

	export default {
		components: {
			DataGrid, Modal
		}
		,props: {
			garantia: {
				type: Object
				,default: () => ({
					id: null
				})
			}
		}
		,data: function() {
			return {
				dataGridConfig: {
					name: 'garantia_mensajes'
					,cols: {
						nombre: 'Nombre'
						,email: 'Email'
						,telefono: 'Teléfono'
						,estatus: 'Estatus'
						,created_at: 'Fecha envio'
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
					,mutators: {
						estatus: function(val) {
							return val == 1 ? 'Sin leer' : 'Leido';
						}
						,created_at: function(val, row, vue) {
							return vue.$moment(val).format('YYYY-MM-DD HH:mm:ss')
						}
					}
				}
				,mensajes: []
				,mensaje: null
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,seleccionados: []
				,seleccionar: false
			}
		}
		,methods: {
			dataGridActions: function(tipo, data) {
				if (tipo == 'options') {
					this.options = data;
					this.obtener_mensajes();
				}else {
					this.seleccionados = data;
					this.seleccionar = true;
				}
			}
			,obtener_mensajes: async function() {
				try {
					let res = (await api.obtener_mensajes(this.garantia.id, this.options)).data;

					this.mensajes = res.data;
					this.dataGridConfig.paginator.pagina_actual = res.current_page;
					this.dataGridConfig.paginator.total_registros = res.total;
					this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
				}catch(e) {
					this.$log.info('error', e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,ver_mensaje: async function() {
				if (this.seleccionados.length == 0)
					return this.$helper.showMessage('Error','Debes seleccionar un mensaje a leer','error','alert');

				this.mensaje = this.seleccionados[0];

				if (this.mensaje.estatus == 1)
					await api.leer_mensaje(this.mensaje.id);
			}
			,cerrar_mensaje: function() {
				this.mensaje = null
				this.seleccionar = false;
			}
		}
	}
</script>

<style lang="scss">
	.dataGrid_garantia_mensajes {
		table {
			thead {

			}
		}
	}
</style>